<template>
  <v-app>
    <v-row justify="space-between">
      <v-col cols="12" v-if="isBusy">
        <v-skeleton-loader
          v-bind="attrs"
          type="article, actions"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" v-else>
        <v-card flat rounded>
          <v-card-title class="d-flex flex justify-content-between">
            {{ lesson_plan.title || "N/A" }}
            <v-switch v-model="edit" label="Edit" hide-details></v-switch>
          </v-card-title>
          <v-card-subtitle>
            {{ lesson_plan.course_title }} | <strong>Batch</strong> :
            {{ lesson_plan.academic_year_title || "N/A" }} |
            <strong>Classrooms</strong> :
            {{ lesson_plan.academic_class_title || "N/A" }} |
            <strong>Total Assignments</strong> :
            {{ lesson_plan.total_assignment || "0" }} |
            <strong>Total lessons</strong> :
            {{ lesson_plan.total_lessons || "0" }}

            <v-btn
              class="float-right my-2"
              @click="topicModelList = true"
              depressed
              color="blue"
              dark
              >Sort Topic</v-btn
            >
          </v-card-subtitle>
          <v-card-text>
            <v-progress-linear
              v-model="lesson_plan.lesson_completed"
              color="green"
              height="5"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="isLessonBusy">
        <v-skeleton-loader
          v-bind="attrs"
          type="article,list-item-three-line, actions"
        ></v-skeleton-loader>
      </v-col>
      <template v-if="!isLessonBusy && lesson_plan_topics.length">
        <v-col cols="4">
          <v-card flat rounded class="lesson_planner_edit">
            <v-card-text class="lesson_planner_unit_edit">
              <v-treeview
                v-if="lesson_plan_topics && lesson_plan_topics.length"
                style="overflow-y: auto"
                :items="lesson_plan_topics"
                item-children="lessons"
                dense
                activatable
                hoverable
                item-key="title"
                item-text="title"
                color="success"
                open-all
                transition
              >
                <template v-slot:prepend="{ item, open, leaf }">
                  <v-icon v-if="item.completed" color="green">
                    {{ open ? "mdi-check-circle" : "mdi-check-circle" }}
                  </v-icon>

                  <v-icon
                    color="blue"
                    v-else-if="
                      lesson_plan.current_lesson &&
                      lesson_plan.current_lesson == item.id
                    "
                  >
                    {{ open && leaf ? "mdi-notebook-outline " : "mdi-pin" }}
                  </v-icon>
                  <v-icon v-else>
                    {{
                      open
                        ? "mdi-book-open-page-variant"
                        : "mdi-notebook-outline"
                    }}
                  </v-icon>
                </template>
                <template v-slot:label="{ item, open, leaf }">
                  <div
                    @click="check(item, leaf)"
                    class="my-auto overflow-visible cursor-pointer"
                  >
                    {{ item.title }}
                  </div>
                </template>
                <template v-slot:append="{ item, open, leaf }">
                  <div class="my-auto overflow-visible">
                    <b-dropdown
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                      v-if="edit && !leaf"
                    >
                      <template v-slot:button-content>
                        <slot>
                          <span>
                            <i class="flaticon-more-1"></i>
                          </span>
                        </slot>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover">
                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            href="#"
                            class="navi-link"
                            @click="__addLesson(item.id)"
                          >
                            <span class="navi-icon">
                              <i class="flaticon-add"></i>
                            </span>
                            <span class="navi-text"> Add lessons</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            href="#"
                            class="navi-link"
                            @click="__addResource(item.id)"
                          >
                            <span class="navi-icon">
                              <i class="flaticon-add"></i>
                            </span>
                            <span class="navi-text">
                              Add activity/resources</span
                            >
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            href="#"
                            class="navi-link"
                            @click="__editUnit(item.id)"
                          >
                            <span class="navi-icon">
                              <i class="flaticon-edit"></i>
                            </span>
                            <span class="navi-text"> Edit</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            href="#"
                            class="navi-link"
                            @click="__deleteTopic(lesson_id, item.id)"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span class="navi-text"> Delete</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </div>
                </template>
              </v-treeview>
              <v-btn
                v-if="edit"
                depressed
                block
                color="blue lighten-2"
                @click="__addUnit()"
                dark
                >Add unit
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="8" v-if="summaryLoading">
          <v-skeleton-loader
            v-bind="attrs"
            type="article, actions"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="8" class=" " v-else>
          <v-scroll-y-transition mode="out-in">
            <!--                    Lesson Summary Start-->
            <v-card
              v-if="lessonView && lessonSummary"
              :key="lessonSummary.id"
              flat
            >
              <v-card-text>
                <div class="d-flex flex justify-content-between">
                  <div>
                    <h2>
                      {{ lessonSummary.topic.title || "N/A" }}

                      <v-chip
                        v-if="
                          lesson_plan.current_lesson &&
                          lesson_plan.current_lesson == lessonSummary.id
                        "
                        x-small
                      >
                        Currently Reading
                      </v-chip>

                      <v-icon v-if="lessonSummary.completed" color="green">
                        mdi-check-circle
                      </v-icon>

                      <small class="font-size-sm">
                        {{ lessonSummary.completed_at | moment("from", "now") }}
                      </small>
                    </h2>
                    <span>
                      <strong>Lecture Hour : </strong
                      >{{ lessonSummary.topic.lecture_hour || "N/A" }}</span
                    >
                  </div>
                  <div>
                    <v-btn
                      v-b-tooltip.hover
                      title="Mark As Current"
                      icon
                      depressed
                      color="green lighten-1"
                      dark
                      @click="markCurrentLesson(lessonSummary.id)"
                    >
                      <v-icon color="blue"> mdi-pin </v-icon>
                    </v-btn>
                    <v-btn
                      v-b-tooltip.hover
                      title="Mark As Complete"
                      icon
                      v-if="!lessonSummary.completed"
                      depressed
                      color="green lighten-1"
                      dark
                      @click="markCompleteLesson(lessonSummary.id)"
                    >
                      <v-icon color="green">
                        mdi-clipboard-check-outline
                      </v-icon>
                    </v-btn>

                    <v-btn
                      v-else
                      v-b-tooltip.hover
                      title="Mark As Incomplete"
                      depressed
                      icon
                      color="orange lighten-1"
                      dark
                      @click="markInCompleteLesson(lessonSummary.id)"
                    >
                      <v-icon color="orange">
                        mdi-clipboard-alert-outline
                      </v-icon>
                    </v-btn>
                    <!--                                </div>-->
                  </div>
                </div>
                <h3 class="text-h5 mb-2">
                  {{ lessonSummary.title }}
                  <span v-if="edit">
                    <i
                      class="flaticon-add ml-2 cursor-pointer"
                      @click="
                        __addLesson(lessonSummary.topic.id, lessonSummary.id)
                      "
                      v-b-tooltip.hover
                      title="Add New"
                    ></i>
                    <i
                      class="flaticon-edit ml-2 cursor-pointer"
                      @click="
                        __editLesson(lessonSummary.topic.id, lessonSummary.id)
                      "
                      v-b-tooltip.hover
                      title="Edit"
                    ></i>
                    <i
                      class="fas fa-trash ml-2 cursor-pointer"
                      @click="
                        __deleteTopicLesson(
                          lessonSummary.topic_id,
                          lessonSummary.id
                        )
                      "
                      v-b-tooltip.hover
                      title="Delete"
                    ></i>
                  </span>
                </h3>
                <div class="ml-2 mb-2">
                  <p v-html="lessonSummary.description"></p>
                </div>

                <!--                            Lesson Wise Activities Or Resources-->
                <div
                  class="card-body"
                  v-if="
                    lessonSummary.activities_resources &&
                    lessonSummary.activities_resources.length
                  "
                >
                  -
                  <v-divider></v-divider>
                  <h3>
                    <b>Unit activities & resources for:</b>
                    {{ lessonSummary.title }}
                  </h3>

                  <v-list three-line>
                    <template
                      v-for="(
                        item, index
                      ) in lessonSummary.activities_resources"
                    >
                      <v-list-item :key="item.id">
                        <v-list-item-avatar>
                          <img
                            v-if="item.activity.full_image_path"
                            :src="item.activity.full_image_path"
                          />
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>
                            <div v-if="checkType(item.activity.type) == 'url'">
                              <a :href="item.url" target="_blank">
                                <strong> {{ item.title }}</strong>
                              </a>

                              <span v-if="edit">
                                <i
                                  class="flaticon-edit ml-4 cursor-pointer"
                                  @click="
                                    __editResourceLesson(
                                      lessonSummary.id,
                                      item.activity.id,
                                      item.id,
                                      item.type,
                                      item.assignment.id
                                    )
                                  "
                                  v-b-tooltip.hover
                                  title="Edit"
                                ></i>
                                <i
                                  class="fas fa-trash ml-4 cursor-pointer"
                                  @click="
                                    __deleteResourceLesson(
                                      lessonSummary.id,
                                      item.id
                                    )
                                  "
                                  v-b-tooltip.hover
                                  title="Delete"
                                ></i>
                              </span>
                            </div>

                            <strong v-else>
                              {{ item.title }}

                              <span v-if="edit">
                                <i
                                  class="flaticon-edit ml-4 cursor-pointer"
                                  v-if="item.type == 'assignment'"
                                  @click="
                                    __editResourceLesson(
                                      lessonSummary.id,
                                      item.activity.id,
                                      item.id,
                                      item.type,
                                      item.assignment.id
                                    )
                                  "
                                  v-b-tooltip.hover
                                  title="Edit"
                                ></i>
                                <i
                                  class="flaticon-edit ml-4 cursor-pointer"
                                  v-if="item.type != 'assignment'"
                                  @click="
                                    __editResourceLesson(
                                      lessonSummary.id,
                                      item.activity.id,
                                      item.id,
                                      item.type
                                    )
                                  "
                                  v-b-tooltip.hover
                                  title="Edit"
                                ></i>
                                <i
                                  class="fas fa-trash ml-4 cursor-pointer"
                                  @click="
                                    __deleteResourceLesson(
                                      lessonSummary.id,
                                      item.id
                                    )
                                  "
                                  v-b-tooltip.hover
                                  title="Delete"
                                ></i>
                              </span>
                            </strong>
                          </v-list-item-title>
                          <v-list-item-content>
                            <a
                              v-if="
                                item.type != 'assignment' &&
                                item.type != 'quiz' &&
                                item.type != 'video' &&
                                item.type != 'external_links' &&
                                item.type != 'book_url'
                              "
                              :href="item.path.real"
                              target="_blank"
                              >download</a
                            >
                            <router-link
                              :to="{
                                name: 'assignment-summary',
                                params: { assignment_id: item.assignment.id },
                              }"
                              custom
                              v-if="item.type == 'assignment'"
                            >
                              View Assignment
                            </router-link>
                          </v-list-item-content>
                          <v-list-item-subtitle
                            class="text-truncate"
                            v-html="item.description"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </div>
                <div>
                  <v-btn
                    v-if="edit"
                    class="btn btn-primary text-white cursor-pointer"
                    @click="
                      __addResourceLesson(
                        lessonSummary.topic.id,
                        lessonSummary.id
                      )
                    "
                    ><span class="fa fa-plus"></span> &nbsp; Add activity or
                    resource
                  </v-btn>
                </div>

                <!--                            Lesson Wise Activities Or Resources-->
                <v-divider></v-divider>

                <div
                  class="card-body"
                  v-if="
                    lessonSummary.topic.activity_resources &&
                    lessonSummary.topic.activity_resources.length
                  "
                >
                  <h3><b>Topic activities & resources:</b></h3>
                  <v-list three-line>
                    <template
                      v-for="(item, index) in lessonSummary.topic
                        .activity_resources"
                    >
                      <v-list-item dense :key="item.id">
                        <v-list-item-avatar rounded>
                          <v-img :src="item.activity.full_image_path"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>
                            <div v-if="checkType(item.activity.type) == 'url'">
                              <a :href="item.url" target="_blank">
                                <strong> {{ item.title }}</strong>
                              </a>
                              <span v-if="edit">
                                <i
                                  class="flaticon-edit ml-4 cursor-pointer"
                                  v-if="item.type != 'assignment'"
                                  @click="
                                    __editResource(
                                      lessonSummary.topic.id,
                                      item.activity.id,
                                      item.id
                                    )
                                  "
                                  v-b-tooltip.hover
                                  title="Edit"
                                ></i>
                                <i
                                  class="flaticon-edit ml-4 cursor-pointer"
                                  v-else
                                  @click="
                                    __editResource(
                                      lessonSummary.topic.id,
                                      item.activity.id,
                                      item.id,
                                      item.type,
                                      item.assignment.id
                                    )
                                  "
                                  v-b-tooltip.hover
                                  title="Edit"
                                ></i>
                                <i
                                  class="fas fa-trash ml-4 cursor-pointer"
                                  @click="
                                    __deleteResource(
                                      lessonSummary.topic.id,
                                      item.id
                                    )
                                  "
                                  v-b-tooltip.hover
                                  title="Delete"
                                ></i>
                              </span>
                            </div>

                            <strong v-else>
                              {{ item.title }}
                              <span v-if="edit">
                                <i
                                  class="flaticon-edit ml-4 cursor-pointer"
                                  v-if="item.type != 'assignment'"
                                  @click="
                                    __editResource(
                                      lessonSummary.topic.id,
                                      item.activity.id,
                                      item.id
                                    )
                                  "
                                  v-b-tooltip.hover
                                  title="Edit"
                                ></i>
                                <i
                                  class="flaticon-edit ml-4 cursor-pointer"
                                  v-else
                                  @click="
                                    __editResource(
                                      lessonSummary.topic.id,
                                      item.activity.id,
                                      item.id,
                                      item.type,
                                      item.assignment.id
                                    )
                                  "
                                  v-b-tooltip.hover
                                  title="Edit"
                                ></i>
                                <i
                                  class="fas fa-trash ml-4 cursor-pointer"
                                  @click="
                                    __deleteResource(
                                      lessonSummary.topic.id,
                                      item.id
                                    )
                                  "
                                  v-b-tooltip.hover
                                  title="Delete"
                                ></i>
                              </span>
                            </strong>
                          </v-list-item-title>
                          <v-list-item-content>
                            <a
                              v-if="item.type != 'assignment'"
                              :href="item.path.real"
                              target="_blank"
                              >download</a
                            >
                            <router-link
                              :to="{
                                name: 'assignment-summary',
                                params: { assignment_id: item.assignment.id },
                              }"
                              custom
                              v-else
                            >
                              <v-btn text small link>
                                <span class="navi-icon">
                                  <i class="flaticon-book"></i>
                                </span>
                                <span class="nav-text">View Assignment</span>
                              </v-btn>
                            </router-link>
                            <router-link
                              v-if="item.type == 'assignment'"
                              :to="{
                                name: 'assignment-submission',
                                params: { assignment_id: item.assignment.id },
                              }"
                            >
                              <v-btn text small link>
                                <span class="navi-icon">
                                  <i class="flaticon2-open-text-book"></i>
                                </span>
                                <span class="nav-text">View Submissions</span>
                              </v-btn>
                            </router-link>
                          </v-list-item-content>
                          <v-list-item-subtitle
                            class="list-content-desc"
                            v-html="item.description"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </div>
              </v-card-text>
            </v-card>
            <!--                    Lesson Summary End-->

            <!--                    Unit Summary View Start-->

            <v-card v-if="!lessonView">
              <v-card-text>
                <div class="d-flex flex justify-content-between">
                  <h2>{{ selectedUnit.title || "N/A" }}</h2>
                  <div class="my-auto overflow-visible">
                    <b-dropdown
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                      right
                      no-flip
                      v-if="edit"
                    >
                      <template v-slot:button-content>
                        <slot>
                          <span>
                            <i class="flaticon-more-1"></i>
                          </span>
                        </slot>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover">
                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            href="#"
                            class="navi-link"
                            @click="__addLesson(selectedUnit.id)"
                          >
                            <span class="navi-icon">
                              <i class="flaticon-add"></i>
                            </span>
                            <span class="navi-text"> Add Lessons</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            href="#"
                            class="navi-link"
                            @click="__addResource(selectedUnit.id)"
                          >
                            <span class="navi-icon">
                              <i class="flaticon-add"></i>
                            </span>
                            <span class="navi-text">
                              Add Activity/Resources</span
                            >
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            href="#"
                            class="navi-link"
                            @click="__editUnit(selectedUnit.id)"
                          >
                            <span class="navi-icon">
                              <i class="flaticon-edit"></i>
                            </span>
                            <span class="navi-text"> Edit</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            href="#"
                            class="navi-link"
                            @click="__deleteTopic(lesson_id, selectedUnit.id)"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span class="navi-text"> Delete</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </div>
                </div>
                <span
                  >Lecture hours: {{ selectedUnit.lecture_hour || "N/A" }}</span
                >
                <!--                               <pre>-->
                <!--                                    {{selectedUnit}}-->
                <!--                               </pre>-->
                <p
                  v-if="selectedUnit.description"
                  v-html="selectedUnit.description"
                ></p>
                <v-expansion-panels
                  flat
                  focusable
                  v-if="lessons.length"
                  multiple
                >
                  <draggable
                    v-model="lessons"
                    @change="__sortLesson(lesson_id, lessons)"
                    style="width: 100%"
                    tag="div"
                  >
                    <v-expansion-panel
                      v-for="(topic_lesson, i) in lessons"
                      :key="i"
                    >
                      <v-expansion-panel-header>
                        <strong
                          ><i
                            v-if="edit"
                            class="cursor-drag fas fa-sort handle pr-2"
                          ></i>
                          {{ topic_lesson.title }}
                        </strong>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p class="m-4" v-html="topic_lesson.description"></p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </draggable>
                </v-expansion-panels>
                <v-card class="mt-2">
                  <v-card-title> Resources </v-card-title>

                  <v-card-text>
                    <v-list three-line>
                      <template
                        v-for="(item, index) in selectedUnit.activity_resources"
                      >
                        <v-list-item dense :key="item.id">
                          <v-list-item-avatar rounded>
                            <v-img :src="item.activity.full_image_path"></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>
                              <div
                                v-if="checkType(item.activity.type) == 'url'"
                              >
                                <a :href="item.url" target="_blank">
                                  <strong> {{ item.title }}</strong>
                                </a>
                                <span v-if="edit">
                                  <i
                                    class="flaticon-edit ml-4 cursor-pointer"
                                    v-if="item.type != 'assignment'"
                                    @click="
                                      __editResource(
                                        selectedUnit.id,
                                        item.activity.id,
                                        item.id,
                                        item.type
                                      )
                                    "
                                    v-b-tooltip.hover
                                    title="Edit"
                                  ></i>
                                  <i
                                    class="flaticon-edit ml-4 cursor-pointer"
                                    v-else
                                    @click="
                                      __editResource(
                                        selectedUnit.id,
                                        item.activity.id,
                                        item.id,
                                        item.type,
                                        item.assignment.id
                                      )
                                    "
                                    v-b-tooltip.hover
                                    title="Edit"
                                  ></i>
                                  <i
                                    class="fas fa-trash ml-4 cursor-pointer"
                                    @click="
                                      __deleteResource(selectedUnit.id, item.id)
                                    "
                                    v-b-tooltip.hover
                                    title="Delete"
                                  ></i>
                                </span>
                              </div>

                              <strong v-else>
                                {{ item.title }}
                              </strong>
                            </v-list-item-title>
                            <v-list-item-content>
                              <a
                                v-if="item.type != 'assignment'"
                                :href="item.path.real"
                                target="_blank"
                                >download</a
                              >
                              <router-link
                                :to="{
                                  name: 'assignment-summary',
                                  params: { assignment_id: item.id },
                                }"
                                custom
                                v-else
                              >
                                View Assignment
                              </router-link>
                              <router-link
                                v-if="item.type == 'assignment'"
                                :to="{
                                  name: 'assignment-submission',
                                  params: { assignment_id: item.assignment.id },
                                }"
                                class="navi-link"
                              >
                                <span class="navi-icon">
                                  <i class="flaticon-eye"></i>
                                </span>
                                <span class="navi-text">View Submissions</span>
                              </router-link>
                            </v-list-item-content>
                            <v-list-item-subtitle
                              class="list-content-desc"
                              v-html="item.description"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-card-text>

                  <v-card-text>
                    <v-btn
                      v-if="edit"
                      class="btn btn-primary text-white cursor-pointer"
                      @click="__addResource(selectedUnit.id)"
                      ><span class="fa fa-plus"></span>
                      &nbsp; Add activity or resource
                    </v-btn>
                  </v-card-text>
                </v-card>
                <v-btn
                  class="mt-2"
                  v-if="edit"
                  depressed
                  block
                  color="blue lighten-2"
                  @click="__addUnit()"
                  dark
                  >Add new Unit
                </v-btn>
              </v-card-text>
            </v-card>

            <!--                    Unit Summary View End-->
          </v-scroll-y-transition>
        </v-col>
      </template>
      <div v-else class="col-md-12 text-center">
        <v-card class="p-20">
          <img src="@/assets/media/images/no-data-found.png" />
          <h5 class="mt-10">No Unit/Lesson available. Add Unit/Lesson .</h5>
          <v-btn
            v-if="edit"
            depressed
            block
            color="blue lighten-2"
            @click="__addUnit()"
            dark
            >Add new unit
          </v-btn>
        </v-card>
      </div>
    </v-row>

    <create-and-update ref="topic" @refresh_topic="__get"></create-and-update>
    <create-and-update-lesson
      ref="lesson"
      @refresh_lesson="__get"
    ></create-and-update-lesson>
    <create-and-update-activity
      ref="resource"
      @refresh_lesson="__get"
    ></create-and-update-activity>
    <v-dialog v-model="topicModelList" max-width="800">
      <v-card v-if="lesson_plan_topics">
        <v-card-title>
          Sort topics
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fas fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <draggable
            v-model="lesson_plan_topics"
            @change="__sortTopics(lesson_id)"
            style="width: 100%"
            tag="div"
          >
            <v-expansion-panel
              v-for="(topic, i) in lesson_plan_topics"
              :key="i"
            >
              <v-expansion-panel-header expand-icon="mdi-sort">
                <strong
                  ><i class="cursor-drag fas fa-sort handle pr-2"></i>
                  {{ topic.title }}
                </strong>
              </v-expansion-panel-header>
            </v-expansion-panel>
          </draggable>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { LessonPlannerMixin } from "@/mixins/LessonPlannerMixin";
import CreateAndUpdate from "@/view/pages/courses/summary-content/course-topic/CreateAndUpdate";
import CreateAndUpdateLesson from "@/view/pages/courses/summary-content/lessons/CreateAndUpdate";
import CreateAndUpdateActivity from "@/view/pages/courses/summary-content/ActivityResoruce/CreateAndUpdate";
import draggable from "vuedraggable";
import TopicService from "@/core/services/lesson-planner/topic/TopicService";

const topicService = new TopicService();
export default {
  mixins: [LessonPlannerMixin],
  components: {
    draggable,
    CreateAndUpdate,
    CreateAndUpdateActivity,
    CreateAndUpdateLesson,
  },
  name: "LessonPlanSummary",
  created() {
    this.$bus.on("refresh-lesson-summary", () => {
      this.__get();
    });
  },
  computed: {
    lesson_id() {
      return this.$route.params.lesson_plan_id;
    },
    filterData() {
      return this.lesson_plan_topics.map((unit) => {
        return { lesson: unit.lessons };
      });
    },
  },

  watch: {
    active(newValue, oldValue) {},
  },
  data() {
    return {
      lesson_plan_topics: [],
      lessons: [],
      active: [],
      lessonView: true,
      topicModelList: false,
      selectedUnit: null,
      lessonSummary: null,
      avatar: null,
      attrs: false,
      edit: false,
    };
  },
  mounted() {
    this.getSingleLesson(this.lesson_id);
  },
  methods: {
    check(item, parent) {
      if (parent) {
        this.lessonView = true;
        this.getLessonSummary(item.id);
      } else {
        this.lessonView = false;
        this.getSingleTopic(this.lesson_id, item.id);
        // this.lessons = [];
        // this.selectedUnit = item;
        // this.lessons = item.lessons;
      }
    },
    closeDialog() {
        this.topicModelList=false;
    },
    getSingleTopic(lessonId, id) {
      this.summaryLoading = true;
      topicService
        .show(lessonId, id)
        .then((response) => {
          this.lessons = [];
          this.selectedUnit = response.data.topic;
          this.lessons = this.selectedUnit.lessons;
        })
        .catch((err) => {
          // console.log(err)
        })
        .finally(() => {
          this.summaryLoading = false;
        });
    },
    __get() {
      this.getSingleLesson(this.lesson_id);
    },

    // __sortTopics() {
    //     topicService.sort(this.lesson_id, this.lesson_plan_topics).then((res) => {
    //         this.$snotify.success("Sorted");
    //
    //     }).catch(err => {
    //         this.$snotify.error("Sorted Error");
    //     }).finally(() => {
    //         this.$emit("getCourseSummary");
    //     })
    // },

    // Unit
    __addUnit() {
      this.$refs["topic"].showModal();
    },
    __editUnit(id) {
      this.$refs["topic"].showModal(id);
    },

    // Unit End

    // Lesson
    __addLesson(topic_id) {
      this.$refs["lesson"].showModal(topic_id);
    },
    __editLesson(topic_id, id) {
      this.$refs["lesson"].showModal(topic_id, id);
    },
    // Lesson End

    // Resources
    __addResource(topicId) {
      this.$refs["resource"].showModal(topicId);
    },

    __editResource(topicId, activity_id, id, type, assignment_id) {
      if (type == "assignment") {
        this.$router.push({
          name: "assignment-edit",
          params: { assignment_id: assignment_id },
        });
      } else {
        this.$refs["resource"].showModal(topicId, activity_id, id);
      }
    },
    __deleteResource(topicId, id) {
      this.__deleteAcitivityContent(id);
    },
    __addResourceLesson(topicId, topic_lesson_Id) {
      this.$refs["resource"].showModalTopicLesson(topicId, topic_lesson_Id);
    },

    __editResourceLesson(topicId, activity_id, id, type, assignment_id = null) {
      // console.log(topicId, activity_id, id)
      if (type == "assignment") {
        this.$router.push({
          name: "assignment-edit",
          params: { assignment_id: assignment_id },
        });
      } else {
        this.$refs["resource"].showModal(topicId, activity_id, id);
      }
    },
    __deleteResourceLesson(topicId, id) {
      this.__deleteAcitivityContent(id);
    },

    // Resources End

    updateLessonPlanCompletion() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            if (
              this.completed_topic_ids &&
              this.completed_topic_ids.length > 1
            ) {
              this.completed_topic_ids = this.completed_topic_ids.join(",");
            }
            if (
              this.completed_lesson_ids &&
              this.completed_lesson_ids.length > 1
            ) {
              this.completed_lesson_ids = this.completed_lesson_ids.join(",");
            }
            this.__updateLessonPlan(this.lesson_id);
          }
        },
      });
    },
    markCompleteLesson(id) {
      this.$confirm({
        message: `You want to mark this lesson as complete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.markComplete(id);
          }
        },
      });
    },
    markCurrentLesson(id) {
      this.$confirm({
        message: `You want to set this lesson as current lesson?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.lesson_plan.current_lesson = id;
            this.__updateLessonPlan(this.lesson_id);
          }
        },
      });
    },

    markInCompleteLesson(id) {
      this.$confirm({
        message: `Mark Confirm As Incomplete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.markInComplete(id);
          }
        },
      });
    },
    /*checkType(item){

        }*/
  },
};
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-stepper__step {
  padding: 5px 5px !important;
  align-items: baseline !important;
}

.v-expansion-panel-header {
  padding: 12px 8px !important;
}

.v-stepper {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.v-stepper--vertical {
  padding-bottom: 0 !important;
}

.v-treeview-node__label {
  overflow-y: visible !important;
}
</style>
