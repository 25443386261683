<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card class="activity-card-wrapper">

              <v-toolbar dark>
                <v-card-title class="headline">
                  Activity or resource
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="hideModal">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <v-card-text>
                    <v-row v-if="activities.length">
                        <v-col cols="2" v-for="activity in activities" :key="activity.title">

                            <v-hover
                                    v-slot="{ hover }"
                            >
                                <v-card @click="__addActivityResource(activity.type,activity.id,topicId)"
                                        flat
                                        :elevation="hover ? 2 : 0"
                                        :class="{ 'on-hover': hover }"
                                        class="mx-auto activity-card"
                                >
                                    <v-card-text class="justify-center align-center">
                                        <img v-if="activity.full_image_path" :src="activity.full_image_path"
                                             style="height: 64px; margin: 0 auto; display: block;">
                                        <p class="font-weight-bold text-center mb-0 mt-3">
                                            {{activity.title}}</p>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <activty-resource-manager ref="activity_resources"></activty-resource-manager>


    </v-app>

</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import ActivityService from "@/core/services/lesson-planner/activity/ActivityService";
    import ActivtyResourceManager from "@/view/pages/courses/summary-content/ActivityResoruce/ActivtyResourceManager";

    import {LessonPlannerMixin} from "@/mixins/LessonPlannerMixin";

    const activityService = new ActivityService();


    export default {
        name: 'topics',
        mixins: [LessonPlannerMixin],
        props: ['lesson_planner_id'],
        components: {
            ActivtyResourceManager
        },
        validations: {
            topic: {
                title: {required},
                description: {required},

            }
        },
        computed: {
            lesson_id() {
                return this.$route.params.lesson_plan_id;
            }
        },
        data() {
            return {
                dialog: false,
                edit: false,
                isBusy: false,
                topicId: null,
                activities: [],
                activity: null,
                activity_id: null,
                topic_lesson_id: null,
                content_id: null,
                topic: {
                    title: null,
                    description: null,
                    lecture_hour: null,
                    lesson_planner_id: this.lesson_planner_id,
                    is_active: true
                },
            };
        },
        mounted() {
        },
        methods: {
            __addActivityResource(type, activity_id, topic_id) {
                this.hideModal()
                this.__getActivities();
                if (this.topic_lesson_id)
                    this.$refs["activity_resources"].showModal(type, activity_id, topic_id, this.topic_lesson_id);
                else
                    this.$refs["activity_resources"].showModal(type, activity_id, topic_id);
            },
            showModal(topicId, activity_id = null, id = null) {
                this.__getActivities();
                this.topicId = topicId;
                this.activity_id = activity_id;
                this.content_id = id;
                if (activity_id && id) {
                    this.edit = true
                    this.__get(activity_id)
                } else {
                    this.dialog = true;
                }

                // this.$refs["my-modal"].show();
            },
            showModalTopicLesson(topicId, lesson_id, activity_id = null, id = null) {
                this.__getActivities();
                this.topicId = topicId;
                this.activity_id = activity_id;
                this.topic_lesson_id = lesson_id;
                this.content_id = id;
                if (activity_id && id) {
                    this.edit = true
                    // this.__get(activity_id)
                } else {
                    this.dialog = true;
                }
                // this.$refs["my-modal"].show();
            },
            hideModal() {
                this.dialog = false;
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            },
            __getActivities() {
                activityService
                    .paginate(this.search)
                    .then(response => {
                        this.activities = response.data.data;
                        this.page = response.data.meta.current_page;
                        this.total = response.data.meta.total;
                        this.perPage = response.data.meta.per_page;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },
            __get(id) {
                activityService.show(id).then(response => {
                    this.activity = response.data.activity;
                    this.$refs["activity_resources"].showModal(this.activity.type, this.activity_id, this.topicId, null, this.content_id);
                })
            },


        }
    };
</script>

<style>
    .activity-card-wrapper .activity-card {
        cursor: pointer;
    }
</style>