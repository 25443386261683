<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title>
                        {{`${edit?'Update':'Add new '}`}} {{label}}
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon  @click="resetForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Title" v-model="activity_content.title" outlined dense>

                            </v-text-field>
                            <span class="text-danger" v-if="$v.activity_content.title.$error">Title is required</span>

                        </v-col>
                        <div class="col-12">
                            Description
                            <ckeditor :config="editorConfig" v-model="activity_content.description"></ckeditor>
                            <span class="text-danger"
                                  v-if="$v.activity_content.description.$error">Description is required</span>
                        </div>
                        <div class="col-12" v-if="checkType!='assignment' && checkType=='url'">
                            <v-text-field :label="label" v-model="activity_content.url" type="url" outlined dense>

                            </v-text-field>
                        </div>
                        <div class="col-12" v-if="checkType!='assignment' && checkType=='file'">
                            <v-file-input
                                    v-model="activity_content.file_path"
                                    show-size
                                    outlined
                                    dense
                                    counter
                                    :label="label"
                            ></v-file-input>
                        </div>

                        <div class="col-3">
                            <v-switch
                                    v-model="activity_content.is_active"
                                    label="Status"
                            >
                            </v-switch>
                        </div>
                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn depressed @click="resetForm" class="text-gray btn btn-standard  ">
                                    Cancel
                                </v-btn>
                                <v-btn depressed :loading="isBusy" @click="createOrUpdate"
                                       class="text-white ml-2 btn btn-primary ">
                                    Save
                                </v-btn>
                            </div>

                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import ActivityContentService from "@/core/services/lesson-planner/activity-content/ActivityContentService";
    const activityContentService = new ActivityContentService();
    import LessonPlannerService from "@/core/services/lesson-planner/lesson-planner/LessonPlannerService";


    import {LessonPlannerMixin} from "@/mixins/LessonPlannerMixin";
    const lessonPlannerService = new LessonPlannerService();

    export default {
        name: 'CreateActivity',
        mixins: [LessonPlannerMixin],
        validations: {
            activity_content: {
                title: {required},
                description: {required},

            }
        },
        computed: {
            lesson_id() {
                return this.$route.params.lesson_plan_id;
            },

          checkType() {
            switch (this.type) {
              case 'assignment':
                this.label = 'assignment';
                return 'assignment';
                break;
              case 'quiz':
                this.label = 'quiz';
                return 'quiz';
                break;
              case 'video':
                this.label = 'Video Url';
                return 'url';
                break;
              case 'image':
                this.label = 'Upload Image';
                return 'file';
                break;
              case 'external_links':
                this.label = 'External Links';
                return 'url';
                break;
              case 'book_url':
                this.label = 'Book Url';
                return 'url';
                break;
              case 'pdf':
                this.label = 'PDF';
                return 'file';
                break;
              case 'ppt':
                this.label = 'PPT';
                return 'file';
                break;
              case 'excel':
                this.label = 'Excel';
                return 'file';
                break;
              case 'csv':
                this.label = 'Csv';
                return 'file';
                break;
              default :
                this.label = "Image";
                return 'file'
            }
          },


        },
        data() {
            return {
                dialog: false,
                edit: false,
                isBusy: false,
                topic_id: null,
                type: null,
                model: null,
                label: null,

                activity_content: {
                    title: null,
                    description: null,
                    course_id: null,
                    type: null,
                    file_path: null,
                    url: null,
                    is_active: true
                },

                editorConfig: {
                    versionCheck: false,
                    toolbar: [
                    [
                        "Bold",
                        "Italic",
                        "Underline",
                        "Strike",
                        "RemoveFormat",
                        "Link",
                        "NumberedList",
                        "BulletedList",
                    ],
                    ],
                },
            };
        },
        mounted() {
        },
        methods: {
            getSingleLessonOnly(id) {
                this.isBusy = true;
                lessonPlannerService.show(id).then(response => {
                    this.lesson_plan = response.data.lesson;
                    this.activity_content.course_id = this.lesson_plan.course_id;
                }).catch().finally(()=>{
                    this.isBusy = false;
                })
            },
            showModal(type, activity_id, topicId = null, topic_lesson_id = null, id = null) {
                this.getSingleLessonOnly(this.lesson_id);

                this.type = type;
                this.activity_content.type = type;
                this.activity_content.topic_id = topicId;
                this.activity_content.activity_id = activity_id
                this.activity_content.topic_lesson_id = topic_lesson_id
                this.activity_content.lesson_planner_id = this.lesson_id;
                if (id) {
                    this.edit = true
                    this.__get(id)
                }
                this.dialog = true;
                // this.$refs["my-modal"].show();
            },
            hideModal() {
                this.dialog = false;
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            },
            __get(id) {
                activityContentService.show( id).then(response => {
                    this.activity_content = response.data.activity_content
                })
            },
            generateFd() {
                let fd = new FormData();
                for (let key in this.activity_content) {
                    if (
                        key == "cover_image" &&
                        this.activity_content["file_path"] &&
                        this.activity_content["file_path"] != null
                    ) {
                        fd.append("file_path", this.activity_content[key]);
                    } else if (key == "is_active" && this.activity_content["is_active"]) {
                        fd.append("is_active", this.activity_content ? 1 : 0);
                    } else {
                        if (this.activity_content[key] != null) {
                            fd.append(key, this.activity_content[key]);
                        }
                    }
                }
                return fd;
            },
            createOrUpdate() {
                this.$v.activity_content.$touch();
                if (this.$v.activity_content.$error) {
                    setTimeout(() => {
                        this.$v.activity_content.$reset();
                    }, 3000);
                } else {
                    let fd = this.generateFd();
                    if (this.edit) {
                        this.__update(fd);
                    } else {
                        this.__create(fd);
                    }
                }
            },
            __update(fd) {
                this.isBusy = true;
                activityContentService
                    .update( this.activity_content.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");
                        this.$bus.emit('refresh-lesson-summary')

                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },

            checkSize(file) {
                let size = file.size / 1024 / 1024;
                let mbSize = size.toFixed(2);
                if (mbSize > 20) {
                    this.sizeExceed = true;
                } else {
                    this.sizeExceed = false;
                }
            },
            __create(fd) {
                this.isBusy = true;
                activityContentService
                    .store( fd)
                    .then((response) => {
                        this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Information added");
                        this.$bus.emit('refresh-lesson-summary')

                        if (this.type == 'assignment'){
                            this.$router.push({ name: 'assignment-edit',params:{assignment_id : response.data.result.id} })
                        }
                        if (this.type == 'quiz'){
                            this.$router.push({ name: 'quiz-update',params:{quiz_id : response.data.result.id} })
                        }

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },
            resetForm() {
                this.$v.activity_content.$reset();
                this.edit = false;
                this.activity_content = {
                    title: null,
                    description: null,
                    course_id: null,
                    type: null,
                    file_path: null,
                    url: null,
                    is_active: true
                };

                this.hideModal();
                this.$emit("refresh_lesson");
                this.$emit("getCourseSummary");
            }
        },


    };
</script>
